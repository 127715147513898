import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Presentation from '../../2molecules/presentation/presentation.js';
import Portfolio from '../../4ecosystems/portfolio/portfolio.js';
import Footer from '../../3organisms/footer/footer.js';


// Criação do estilo
const styles = {
    portfolio:{
    },
};

class HomePage extends Component {

    render(){
        const { classes} = this.props;
        return (
            <div className={classes.back}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Presentation/>
                    </Grid>
                    <Grid item xs={12}>
                        <Portfolio/>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(HomePage);
import React, { Component } from 'react';
import * as firebase from 'firebase';
import './App.css';

import HomePage from './components/5enviroments/home_page/home_page.js'

class App extends Component {
    
    constructor(){
        super();
        this.state={
            uid: null,
        };
    }

    UNSAFE_componentWillMount(){
        
        // Initialize Firebase
        var config = {
            apiKey: "AIzaSyBa2nYKbushuwLWy6ZG8Fupkk7YAMsjVh4",
            authDomain: "joaoricardofl-c5b5a.firebaseapp.com",
            databaseURL: "https://joaoricardofl-c5b5a.firebaseio.com",
            projectId: "joaoricardofl-c5b5a",
            storageBucket: "joaoricardofl-c5b5a.appspot.com",
            messagingSenderId: "553257158763"
        };
      firebase.initializeApp(config);
        
        !firebase.apps.length ? firebase.initializeApp(config).firestore().settings({ timestampsInSnapshots: true }) : firebase.app().firestore().settings({ timestampsInSnapshots: true });

        firebase.auth().useDeviceLanguage();
        
    }
    
    
  render() {
    return (
      <div>
        
        <HomePage/>

      </div>
    );
  }
}

export default App;

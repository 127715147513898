import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import Grid from '@material-ui/core/Grid';

import Title from '../../1atoms/title/title.js';
import ListCards from '../../3organisms/list_cards/list_cards.js';

import ImageMind from '../../media/mindzstorm.jpg';
import ImageWont from '../../media/escrito_fb.jpeg';
import ImageMelhor from '../../media/MelhorPrato2.png';


// Criação do estilo
const styles = {
    root: {
        flexGrow: 1,
        paddingTop: 40,
        //position: 'relative',
    },
    row_title:{
        backgroundColor: deepPurple[500],
        paddingTop: 80,
        minHeight: 200,
    },
    portfolio:{
        paddingRight: 10,
        paddingLeft: 10,
        marginTop: 20,
        marginBottom: 20,
    },
};

class Portfolio extends Component {
    
    render(){
    const { classes } = this.props;    
    const title1 = 'Portfolio';
        
    var sites =[];
    
    sites[0]= {site: 'melhorespratos.com.br', devtools: ['HTML', 'CSS', 'ReactJS', 'Firebase', 'Material Design','Atomic design'], site_logo: ImageMelhor};
    sites[1]= {site: 'wontgivup.com', devtools: ['HTML', 'CSS', 'ReactJS', 'Firebase', 'Material Design','Atomic design'], site_logo: ImageWont};
    sites[2]= {site: 'mindzstorm.com', devtools: ['HTML', 'CSS', 'ReactJS', 'Firebase', 'Material Design','Atomic design'], site_logo: ImageMind};
        
    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.row_title}>
                    <Title title={title1} titlecss={'portfolio_title'}/>
                </Grid>
                <Grid item xs={12} className={classes.portfolio}>
                    <ListCards sites={sites}/>
                </Grid>
            </Grid>
        </div>
        );
    }
}

Portfolio.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Portfolio);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';


// Criação do estilo
const styles = {
  root_title: {
      flexGrow: 1,
  },
    font_title: {
        color: blueGrey[800],
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '10px',
        marginBottom: '0px',
        wordWrap: 'break-word',
//        fonstSize: 48,
    },
    font_description: {
        color: blueGrey[500],
//        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '10px',
        marginBottom: '0px',
        fonstSize: 24,
        maxWidth: 800,
        margin: 'auto',
    },
    portfolio_title:{
        color: blueGrey[50],
        textAlign: 'center',
        paddingTop: '10px',
        margin: 'auto',
        fontWeight: 'bold',
    },
    font_site:{
        color: blueGrey[500],
        textAlign: 'left',
        paddingTop: '10px',
        margin: 'auto',
        fontWeight: 'bold',
        fontSize: 14,
    },
    font_devtools:{
        color: blueGrey[500],
        textAlign: 'center',
        paddingTop: '10px',
        margin: 'auto',
        //fontWeight: 'bold',
        fontSize: 14,
    }
};

function Title (props) {
    const { classes } = props;
    var card_css = classes.font_title;
    var variant="h3";
    var component="h2"
    
    if (props.titlecss === 'font_description'){ 
        card_css = classes.font_description; 
        variant="h5";
    }
    
    if(props.titlecss === 'font_site') { card_css = classes.font_site; }
    if (props.titlecss === 'portfolio_title'){ card_css = classes.portfolio_title; }
    if (props.titlecss === 'font_devtools'){ card_css = classes.font_devtools; }

    return (
        <div className={classes.root_title}>
            <Typography className={card_css} variant={variant} component={component} gutterBottom>{props.title}</Typography>
        </div>
    );
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Title);
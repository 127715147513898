import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Email from '@material-ui/icons/Email';

import Grid from '@material-ui/core/Grid';

// Criação do estilo
const styles = {
    root:{
        backgroundColor: deepPurple[500],
        marginTop: 10,
    }, 
    footer:{
        textAlign: 'center',
    },
    icons:{
        color: deepPurple[50],
        fontSize: 20,
    },
    icons2:{
        color: deepPurple[50],
        fontSize: 24,
    }
};


function Footer (props) {   
    const { classes } = props;
    
    return (
        <div className={classes.root}>
            <Grid container spacing={0} alignItems="center" className={classes.footer}>
                <Grid item xs={12} alignItems="center">
                        <BottomNavigation className={classes.root}>
                          <BottomNavigationAction label="Linkedin" value="linkedin" icon={<a rel="noopener noreferrer" target="_blank" href={"https://www.linkedin.com/in/joão-lemos-4aba6b24"} className={classes.icons}><i class="fab fa-linkedin" ></i></a>} />
        
                         <BottomNavigationAction label="Github" value="Github" icon={<a rel="noopener noreferrer" target="_blank" href={"https://github.com/joaoricardofl"} className={classes.icons}><i class="fab fa-github" ></i></a>} />
        
                          <BottomNavigationAction  label="Email:joaoricardofl@gmail.com" value="email" icon={<a rel="noopener noreferrer" target="_blank" href="mailto:joaoricardofl@gmail.com" className={classes.icons2}><Email /></a>} />
        
                        </BottomNavigation>
                </Grid>
            </Grid>
        </div>
    );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Footer);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import blueGrey from '@material-ui/core/colors/blueGrey';

//import ImageMind from '../../media/paper-2221812_1920.jpg';
import Title from '../../1atoms/title/title.js';

// Criação do estilo
const styles = {
    root: {
        flexGrow: 1,
        top: '17vh',
        width: 300,
    },
    card:{
      paddingTop: 20,  
    },
    media:{
//        minHeight: 200,
        position: 'relative',
        height: 140,
        width: '100%',
    },
    expanded:{
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
    list_item:{
        padding: 0,
        textAlign: 'center',
        paddingTop: '10px',
        margin: 'auto',
    },
    list_item_text:{
        color: blueGrey[500],
        fontSize: 14,
    }
};

class CardSite extends Component {

    constructor(){
        super();
        this.state={
            expanded: false, 
        };
        this.handleExpand=this.handleExpand.bind(this);
    }

    handleExpand(){ this.setState({ expanded: !this.state.expanded });    }
    
    render(){
    const { classes } = this.props;
    var class_expand;
    (!this.state.expanded)? class_expand = classes.expanded : class_expand = classes.expandOpen ;
    const site = 'Site: ';
    const dev_title ='Desenvolvimento: ';
    var devtoolss = [];
    var dev, qtde;
    (this.props.devtools.length !== 0) ? qtde = this.props.devtools.length : qtde = 1;
    for (var i = 0; i < qtde; i++) {
        dev = this.props.devtools[i];
        devtoolss.push( 
            <Grid key={i} item xs={12}>
                <Grid container justify="center" className={classes.pad_rows}>
                    <Title title={dev} titlecss={'font_devtools'}/>
                </Grid>
            </Grid>
        );
    }
        
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
        
             <CardMedia className={classes.media} image={this.props.site_logo} title="mindzstorm"   />
            
              <CardActions className={classes.actions} disableActionSpacing>
                <IconButton className={class_expand} onClick={this.handleExpand} aria-expanded={this.state.expanded} aria-label="Mais informações"  >
                      
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <Title title={site} titlecss={'font_site'}/>
                        </Grid>
                        <Grid item xs={8}>
                            <a rel="noopener noreferrer" target="_blank" href={"https://" + this.props.site}>{this.props.site}</a>
                        </Grid>
                        <Grid item xs={12}>
                            <Title title={dev_title} titlecss={'font_site'}/>
                        </Grid>
                        {devtoolss}
                    </Grid>
                </CardContent>
              </Collapse>
            </Card>
        </div>
    );
    }
}

CardSite.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(CardSite);
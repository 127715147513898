import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import Grid from '@material-ui/core/Grid';

import Cards from '../../2molecules/cards/cards.js';

// Criação do estilo
const styles = {
    pad_rows:{
//        paddingTop: '15px'
    }, 
    list_cards: {
        //paddingTop: '20px',
//        paddingBottom: '20px',
        backgroundColor: grey[50],
        //position: 'relative',
    },
    btn_post:{
        display: 'block',
        textAlign: 'center',
        //paddingTop: '-30px',
        paddingBottom: '10px',
    }
};

//                    <Cards desafio={desafio} desafioid={desafioid} cards_size={props.cards_size}/>


function ListCards (props) {   
    const { classes } = props;
    var cardss = [];
    var site, devtools, site_logo, qtde;
    (props.sites.length !== 0) ? qtde = props.sites.length : qtde = 1;
    for (var i = 0; i < qtde; i++) {
        site = props.sites[i].site;
        devtools = props.sites[i].devtools;
        site_logo = props.sites[i].site_logo;
        cardss.push( 
            <Grid key={i} item xs={12} sm={6} md={4}>
                <Grid container justify="center" className={classes.pad_rows}>
                    <Cards site={site} devtools={devtools} site_logo={site_logo}/>
                </Grid>
            </Grid>
        );
    }
    
    
    return (
        <div className={classes.list_cards}>
            <Grid container spacing={16}>
                {cardss}
            </Grid>
        </div>
    );
}

ListCards.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(ListCards);
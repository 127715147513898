import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Title from '../../1atoms/title/title.js';
import Imgme from '../../media/GOPR2318.jpg';

// Criação do estilo
const styles = {
    root: {
        flexGrow: 1,
        paddingTop: '17vh',
        //position: 'relative',
    },
    description_grid:{
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    imgme_grid:{
        textAlign: 'center',
    },
    imgme:{
        maxWidth: 270,
        borderRadius: '50%',
        margin: 'auto',
        paddingTop: 40,
    }
};

function Presentation (props) {   
    const { classes } = props;    
    const title1 = 'Desenvolvedor Front-end';
    const title2 = 'Eu sou João Ricardo e estou começando na carreira de desenvolvedor, abaixo você pode encontrar mais informações sobre o meu trabalho'
    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Title title={title1}/>
                </Grid>
                <Grid item xs={12} className={classes.description_grid} >
                    <Title title={title2} titlecss={'font_description'}/>
                </Grid>
                <Grid item xs={12} className={classes.imgme_grid}>
                    <img  src={Imgme} className={classes.imgme} alt="Joaoricardofl"/>
                </Grid>
            </Grid>
        </div>
    );
}

Presentation.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Presentation);